/* The sidebar menu */
.sidenav {
  position: static; /* Fixed Sidebar (stay in place on scroll) */
  top: 75px; /* Stay at the top */
  left: 0;
  background-color: #7d90b8; /* Pangea Color */
  overflow-x: hidden; /* Disable horizontal scroll */
  z-index: 1; /* Stay on top */
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-template-rows: auto 0.5fr auto 1fr;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

/* Style page content */
.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

.sidebar-button {
  border-radius: 0 !important;
}
.sidebar-button svg {
  height: 25px;
  width: auto;
}
.sidebar-button span {
  color: #fff;
  font-size: 18px;
  margin: 0 4px;
}
/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}