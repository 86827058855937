.bm-burger-button {
  z-index: 1000;
  position: fixed;
  width: 20px;
  height: 15px;
  left: 14px;
  top: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bm-burger-bars {
  background: white;
}

.bm-cross-button {
  height: 70px;
  z-index: 1;
}
.bm-cross {
  background: #7d90b8;
}

.bm-menu {
  background: white;
  font-size: 1.075em;
}

.bm-menu-wrap {
  height: calc(100% - 75px) !important
}

.bm-morph-shape {
  fill: RGB(80, 84, 95);
}

.bm-item-list {
  color: #4f535e;
  font-weight: 550;
  overflow-y: scroll !important;
}

.bm-item {
  display: block;
}
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.async-multi {
  float: right;
}

.export-btn {
  width: 78px;
  height: 30px;
  color: white;
  background-color: rgba(80, 84, 95, 0.35);
  border-radius: 7px;
}

.search-btn {
  width: 78px;
  height: 30px;
  color: white;
  background-color: rgba(80, 84, 95, 0.35);
  border-radius: 7px;
}
