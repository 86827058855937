.select {
    /* max-width: 400px; */
    margin: 0px 2px
}

.select-root {
    padding-right: calc(56px + 28px) !important;
    margin-top: 12px !important;
}

.select-tip {
    position: absolute;
    top: calc(50% - 14px);
    right: calc(56px);
    height: 28px !important
}

.description {
    display: inline;
    display: flex;
    gap: 8px;
    pointer-events: all;
    align-items: center;
}

.range-select-root {
    display: grid;
    grid-template-columns: auto 75px 75px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px
}


.range-select-caption {
    margin-top: 14px !important
}