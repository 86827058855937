#page-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.ReactTable {
  flex: 1;
}
.mission {
  text-align: center;
  color: #808080;
}

.mission-content {
  max-width:1000px;
  margin: auto;  
}


.mission-title {
  color: #9cc2e6;
  padding-bottom: 10px;
  text-decoration: underline #9cc2e6;
}